import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import Helmet from 'react-helmet'

import './base.css'
import Header from './header'
import Footer from './footer'
import Scroll from './scroll'

const DefaultLayout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            author
            description
          }
        }
      }
    `}
    render={data => (
      <div>
        <Helmet>
          <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=G-5Y3QVW6W3K"
          ></script>
          <script>
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-5Y3QVW6W3K');
            `}
          </script>
        </Helmet>
        <Header siteMetadata={data.site.siteMetadata} />
        <div className="w-full md:max-w-2xl md:mx-auto mt-16">{children}</div>
        <Footer />
        <Scroll showBelow={250} />
      </div>
    )}
  />
)

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default DefaultLayout
