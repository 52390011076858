import React from 'react'
import { Link } from 'gatsby'

const TopNav = ({ data }) => {
  return (
    <div className="text-sm text-gray-700">
      <Link to="/" className="mx-3 hover:underline">
        HOME
      </Link>
      <span className="text-gray-300">|</span>
      <Link to="/category/diet" className="mx-3 hover:underline">
        DIET
      </Link>
      <span className="text-gray-300">|</span>
      <Link to="/category/food" className="mx-3 hover:underline">
        FOOD
      </Link>
      <span className="text-gray-300">|</span>
      <Link to="/category/logs" className="mx-3 hover:underline">
        LOG
      </Link>
    </div>
  )
}

export default TopNav
